<template>
  <div class="shipments-page">
    <div class="flex items-center justify-between mb-4">
      <div>
        <h2>{{$t('Cash Collection')}}</h2>
        <p class="mt-2">{{$t('Cash-only orders. No shipment to be delivered.')}}</p>
      </div>
        <shipment :user="user" v-if="$store.state.AppActiveUser.userRole === 'merchant'" :type="type" :shipmentModal="cashCollectionModal" @shipmentModal="cashCollectionModal = $event" @loadShipments="loadCashCollection"></shipment>
    </div>
    <div class="md:flex md:flex-wrap grid grid-cols-2 gap-2 items-center whitespace-nowrap md:mb-6 mb-3">
      <print-label-button :selected="selected"></print-label-button>
      <export-button class="m-0 whitespace-nowrap"></export-button>
    </div>

    <shipblu-table
      :sst="true"
      multiple
      orders
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="cashRequests"
      :tableLoader="tableLoader"
    >
      <template slot="header">
        <div class="lg:w-auto w-full md:mt-0 lg:flex grid grid-cols-1 gap-2 items-center">
          <table-filter icon="BarChart2Icon" filterLabel="Status" :filters="statuses" :statusFilters="statusOptions" @changeFilter="changeStatus" @filterValue="statusValue = $event"/>
        </div>
      </template>
        
      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Created')}}</shipblu-th>
        <!-- <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'merchant'">{{$t('Merchant Info')}}</shipblu-th> -->
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Cash Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th class="step-12 order-1 flex justify-center" v-if="(!$route.params.merchantID && !$route.params.warehouseID) || $store.state.AppActiveUser.userRole === 'customer-support'">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`" :to="{ name: `${$store.state.AppActiveUser.userRole}-cash-collections`, params: { type: 'cash-collections', orderID: data[indextr].id }}">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-13 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].tracking_number }}
                <span @click="copied(data[indextr].tracking_number)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].created" class="order-4">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Created')}}</p>
            <p class="sm:text-base text-sm">{{ common.formatDate(data[indextr].created, $i18n.locale) }}</p>
            <p class="text-sm sm:text-secondary sm:mt-2 mt-1">{{ $i18n.locale === 'en' ? common.gettingDate(data[indextr].created).split(',')[0] : common.gettingDate(data[indextr].created).split('،')[0] }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerInfo" class="order-6 sm:pointer-events-none">
            <div v-on:click.stop>
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Customer')}}</p>
              <p class="sm:text-base text-sm">{{ data[indextr].customer.full_name }}</p>
              <p class="text-sm flex gap-1 sm:text-secondary sm:mt-2 mt-1">
                {{ data[indextr].customer.phone }}
                <span @click="copied(data[indextr].customer.phone)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <!-- <shipblu-td v-if="$store.state.AppActiveUser.userRole !== 'merchant'" :data="data[indextr].merchantInfo">
            {{ data[indextr].merchant.name }}
          </shipblu-td> -->

          <shipblu-td :data="data[indextr].location" class="order-7">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Location')}}</p>
            <p class="sm:text-base text-sm">{{ $t(data[indextr].customer.address.zone.name) }}</p>
            <p class="text-sm sm:text-secondary sm:mt-2 mt-1">{{ $t(data[indextr].customer.address.zone.city.name) }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].packages" class="order-8">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Cash Amount')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].amount && parseInt(data[indextr].amount) > 0 ? ((Number(data[indextr].amount)).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'N/A' }} </p>
          </shipblu-td> 

          <shipblu-td class="order-9" :data="data[indextr].status" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;" @click="removeAttribute($event), openNotificationFailureData(data[indextr])">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right" @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item @click="viewCashCollection('View', data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="EyeIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('View')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <template v-if="$store.state.AppActiveUser.userRole !== 'head-of-fleet'">
                    <vs-dropdown-item
                      v-if="data[indextr].status === 'created'"
                      @click="confirmUpdateCashStatus(data[indextr], 'collection_requested')"
                    >
                      <span class="flex items-center">
                        <feather-icon
                          icon="DollarSignIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Request Collect</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      v-if="data[indextr].status === 'created'"
                      @click="updateCashStatus(data[indextr], 'cancel this order', 'cancelled')"
                    >
                      <span class="flex items-center">
                        <feather-icon
                          icon="XCircleIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Cancel Request</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      v-if="data[indextr].status === 'collected'"
                      @click="getInvoice(data[indextr])"
                    >
                      <span class="flex items-center">
                        <feather-icon
                          icon="DownloadIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Download Invoice')}}</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      v-if="$store.state.AppActiveUser.userRole === 'admin'"
                      @click="blockCustomer(data[indextr])"
                    >
                      <span class="flex items-center">
                        <feather-icon
                          icon="UserXIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Block Customer')}}</span>
                      </span>
                    </vs-dropdown-item>
                  </template>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationObj" @changePageSize="changePageSize" @changePageNumber="changePageNumber($event)" />
    <print :selected="selected"></print>
    <block-customer :blockCustomerModal="blockCustomerModal" @blockCustomerModal="blockCustomerModal = $event" :data="customer" @loadShipments="loadCashCollection"></block-customer>
  </div>
</template>

<script>
import Shipment from './components/Shipment.vue'
import Print from '../merchant/components/PrintLabels.vue'
import i18nData from '../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper.js'
import BlockCustomer from '../admin/components/BlockCustomer.vue'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ExportButton from '../headOfFleet/components/ExportButton.vue'
import PrintLabelButton from '../headOfFleet/components/PrintLabelButton.vue'
import TableFilter from './components/TableFilter.vue'
import ShipbluCursorPagination from '../../layouts/components/ShipbluCursorPagination.vue'

export default {
  data () {
    return {
      common,
      user: {},
      selected: [],
      cashRequests: [],
      type: '',
      cashCollectionModal: false,
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      searchVal: '',
      paginationObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      cashData: {},
      cashStatus: '',
      file_URl: '',
      searchInProgress: false,
      searchedValue: ' ',
      blockCustomerModal: false,
      customer: {},
      tableLoader: false,
      statusValue: '',
      statusOptions: [
        {
          name: 'all',
          value: 'all',
          color: ''
        },
        {
          name: 'created',
          value: 'created',
          color: 'bg-dark'
        },
        {
          name: 'collection requested',
          value: 'collection_requested',
          color: 'bg-warning'
        },
        {
          name: 'out for collection',
          value: 'out_for_collection',
          color: 'bg-primary'
        },
        {
          name: 'collection attempted',
          value: 'collection_attempted',
          color: 'bg-danger'
        },
        {
          name: 'collected',
          value: 'collected',
          color: 'bg-success'
        },
        {
          name: 'failed',
          value: 'failed',
          color: 'bg-danger'
        },
        {
          name: 'cancelled',
          value: 'cancelled',
          color: 'bg-dark'
        }
      ],
      statuses:[]
    }
  },
  watch: {
    '$route.query.status' () {
      if (!this.$route.query.status) {
        this.statuses = []
        this.loadCashCollection()
      }
    },
    '$route.params.warehouseID' () {
      this.maximumItems = process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
      this.loadCashCollection()
    }
  },
  methods: {
    async copied (data) {
      try {
        await navigator.clipboard.writeText(data)
      } catch ($e) {
        this.$vs.notify({
          color:'dark',
          title:i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    },
    changePageNumber (val) {
      this.loadCashCollection(this.paginationObj[val])
      this.paginationObj.currentPage = val === 'next' ? this.paginationObj.currentPage + 1 : this.paginationObj.currentPage - 1
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.loadCashCollection()
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    blockCustomer (data) {
      this.customer = data
      this.blockCustomerModal = true
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadCashCollectionsSearch)
    },
    loadCashCollectionsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadCashCollection()
    },
    changeStatus () {
      this.statuses = this.statuses.includes(this.statusValue) ? this.statuses.filter(item => item !== this.statusValue) : [...this.statuses, this.statusValue]
      if (this.statusValue === 'all') this.statuses = []
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-cash-collections-view`,
        query: {
          status: this.statuses
        }
      }).catch(() => {})
      this.loadCashCollection()
    },
    loadCashCollection (cursorVal) {
      if (!cursorVal) this.paginationObj.currentPage = 1
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?status=${this.statuses}&cursor=${cursorVal ? cursorVal : ''}&limit=${this.maximumItems}&search=${this.searchVal}&warehouse_code=${this.$route.params.warehouseID ? this.$route.params.warehouseID : ''}`
      sendRequest(true, false, this, `api/v1/cash-collections/${query}`, 'get', null, true,
        (response) => {
          this.cashRequests = response.data.results
          this.paginationObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    viewCashCollection (type, data) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-cash-collections`,
        params: {
          type: 'cash-collections',
          orderID: data.id,
          warehouseID: this.$store.state.AppActiveUser.userRole === 'head-of-fleet' ? this.$route.params.warehouseID : ''
        }
      }).catch(() => {})
    },
    updateCashStatus (data, sentence, status) {
      this.cashData = data
      this.cashStatus = status
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: `Are you sure you want to ${sentence} ?`,
        acceptText: 'Yes',
        accept: this.confirmUpdateCashStatus
      })
    },
    confirmUpdateCashStatus (data, status) {
      if (this.$store.state.AppActiveUser.userRole === 'merchant' && this.user.has_tax_profile === false) {
        this.$vs.dialog({
          color: 'danger',
          title: i18nData[this.$i18n.locale]['Oops!'],
          text: i18nData[this.$i18n.locale]['You don\'t have a tax profile to perform this action!'],
          acceptText: i18nData[this.$i18n.locale]['Choose Account type'],
          accept: this.goToAddTaxProfile
        })
      } else {
        const cashCollection = [
          {
            status: status ? status : this.cashStatus,
            cash_collection_request: data ? data.id : this.cashData.id
          }
        ]
        sendRequest(false, false, this, 'api/v1/tracking/cash-collections/', 'post', cashCollection, true,
          () => {
            this.$vs.notify({
              color:'success',
              title:i18nData[this.$i18n.locale]['Success'],
              text:i18nData[this.$i18n.locale]['Cash Collection'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
              position: 'top-center'
            })
            this.loadCashCollection()
          }
        )
      } 
    },
    goToAddTaxProfile () {
      this.$router.push({
        name: 'merchant-profile',
        params: {
          tab: 'tax-profile'
        }
      }).catch(() => {})
    },
    getInvoice (data) {
      sendRequest(false, false, this, `api/v1/billing/merchants/${data.merchant.id}/invoices/?invoicecashcollection__order=${data.id}`, 'get', null, true,
        (response) => {
          if (response.data.results.length > 0) {
            this.downloadInvoice(response.data.results[0].id)
          } else {
            this.$vs.notify({
              color:'warning',
              title:i18nData[this.$i18n.locale]['Warning'],
              text:i18nData[this.$i18n.locale]['Invoice isn\'t ready'],
              position: 'top-center'
            })
          }
        }
      )
    },
    downloadInvoice (invoiceId) {
      sendRequest(false, false, this, `api/v1/billing/invoices/${invoiceId}/pdf/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    }
  },
  components: {
    Print,
    Shipment,
    BlockCustomer,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ExportButton,
    PrintLabelButton,
    TableFilter,
    ShipbluCursorPagination
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.statuses = this.$route.query.status ? typeof (this.$route.query.status) === 'string' ? Array(this.$route.query.status) : this.$route.query.status : []
    if (this.$store.state.AppActiveUser.userRole === 'merchant') {
      const merchantData = common.checkMerchantInLocalStorage(this)
      merchantData.then(results => {
        this.user = results.merchantData
        this.loadCashCollection()
      })
    } else {
      this.loadCashCollection()
    }
  }
}
</script>

<style>
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
